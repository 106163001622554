import {ActionType, createAction} from "typesafe-actions";
import {wrapInDispatch} from "../../../helpers/wrapInDispatch";
import {SortDirection} from "../sortingType";

export const sortingActions = {
	clearSorting: createAction("sortingActions/clearSorting", (tableKey: string) => ({tableKey}))(),
	setSorting: createAction("sortingActions/setSorting", (tableKey: string, sortKey: string) => ({
		tableKey,
		sortKey,
	}))(),

	setDefaultSortingKeys: createAction(
		"sortingActions/setDefaultSortingKeys",
		(tableKey: string, sortingKeys: string[], sortingDirectionValue: Record<string, SortDirection>) => ({
			tableKey,
			sortingKeys,
			sortingDirectionValue,
		}),
	)(),
};

export type SortingAction = ActionType<typeof sortingActions>;
export const sortingActionsWithDispatch = wrapInDispatch(sortingActions);
