import {createSelector} from "reselect";
import {AppState} from "redux/AppState";
import {getDurationTimeUnit} from "../../../taskRegistration/helpers/common";

/** Extract `behaviorExperiment` state from AppState */
const getBehaviorExprState = (state: AppState) => state.behaviorExperiment;

/** Get event id from `behaviorExperiment` state */
export const getBehaviorExprEventId = (state: AppState) => getBehaviorExprState(state).eventId;

/** Get `isReplan` from `behaviorExperiment` state  */
export const getBehaviorExprIsReplan = (state: AppState) => getBehaviorExprState(state).isReplan;

/** Get `savingState` from `behaviorExperiment` state */
export const getBehaviorExprSavingState = (state: AppState) => getBehaviorExprState(state).savingState;

/** Get `isFormOpened` condition from `behaviorExperiment` state */
export const getIsBehaviorExprFormOpened = (state: AppState) => {
	const {isFormOpened, userId} = getBehaviorExprState(state);

	return isFormOpened && !!userId;
};

/** Get behavior expr saved values by user id from `behaviorExperiment` state */
export const getBehaviorExprSavedValuesByUserId = (state: AppState) => {
	const {savedFormValues, userId} = getBehaviorExprState(state);

	return savedFormValues[userId];
};

/**
 * Return draft form values.
 */
export const getSavedFormValues = (state: AppState) => getBehaviorExprState(state).savedFormValues;

/**
 * return detail behavior expr
 */
export const getBehaviorExprDetailResponse = (state: AppState) => getBehaviorExprState(state).behaviorExprDetail;

/**
 * Transform EventViewData into BehaviorExprFormValues.
 */
export const getFormValuesFromEventViewData = createSelector(getBehaviorExprDetailResponse, (detailResponse) => {
	if (!detailResponse) {
		return null;
	}

	const expectation = detailResponse.expectation;

	const {duration, timeUnit} = getDurationTimeUnit(detailResponse.expiresAt, detailResponse.remindAt);

	return {
		negativeExpectation: expectation.negativeThought,
		negativeExpectationBelievability: expectation.negativeBelievability,
		rationalExpectation: expectation.rationalThought,
		rationalExpectationBelievability: expectation.rationalBelievability,
		description: detailResponse.description,
		negativeExpectationConsequence: expectation.negativeConsequence,
		rationalExpectationConsequence: expectation.rationalConsequence,
		title: detailResponse.title,
		expiresAt: detailResponse.expiresAt,
		reminderToggle: !!detailResponse.remindAt,
		reminderDuration: duration,
		reminderTimeUnit: timeUnit,
	};
});

/** get behavior experiment loadingState */
export const getBehaviorExprLoadingState = (state: AppState) => {
	return getBehaviorExprState(state).loadingState;
};

/** get behavior experiment isDetailModalOpened status */
export const isBehaviorExprDetailModalOpened = (state: AppState) => {
	return getBehaviorExprState(state).isDetailModalOpened;
};

/** only when the behavior experiment is not rescheduled. */
export const getIsBehaviorExperimentEditing = (state: AppState) => {
	const {behaviorExprDetail, isReplan} = getBehaviorExprState(state);

	return !!behaviorExprDetail && !isReplan;
};

/** get behavior experiment user id state */
export const getBehaviorExperimentUserId = (state: AppState) => {
	return getBehaviorExprState(state).userId;
};

/** get behavior experiment deleting state */
export const getBehaviorExperimentDeletingState = (state: AppState) => {
	return getBehaviorExprState(state).deletingState;
};
