import produce from "immer";
import {GroupTherapyReduxState} from "../groupTherapy";
import {GroupTherapyActions, groupTherapyActions} from "./groupTherapyActions";
import {getType} from "typesafe-actions";
import {arrToMap} from "../../helpers/arrToMap";
import {LoadingState} from "constants/redux";

const initialState: GroupTherapyReduxState = {
	loadingState: LoadingState.EMPTY,
	savingState: LoadingState.EMPTY,
	deletingState: LoadingState.EMPTY,
	registrationFetchState: LoadingState.EMPTY,
	registrationSavingState: LoadingState.EMPTY,
	registrationDeletingState: LoadingState.EMPTY,
	groupMap: {},
	registrationDetails: null,
	relatedSensorDataMap: {},
};

export const groupTherapyReducer = produce((state: GroupTherapyReduxState, action: GroupTherapyActions) => {
	switch (action.type) {
		case getType(groupTherapyActions.fetchGroupTherapyList.request): {
			state.loadingState = LoadingState.LOADING;
			return;
		}

		case getType(groupTherapyActions.fetchGroupTherapyList.success): {
			state.groupMap = arrToMap(action.payload.groups, (group) => group.id);
			state.loadingState = LoadingState.LOADED;
			return;
		}

		case getType(groupTherapyActions.fetchGroupTherapyList.failure): {
			state.loadingState = LoadingState.ERROR;
			return;
		}

		case getType(groupTherapyActions.fetchGroupRegistrationList.request): {
			state.registrationFetchState = LoadingState.LOADING;
			return;
		}

		case getType(groupTherapyActions.fetchGroupRegistrationList.success): {
			const {id, registrations, totalRegistrations} = action.payload;
			state.groupMap[id].groupRegistrations = registrations;
			state.groupMap[id].totalRegistrations = totalRegistrations;
			state.registrationFetchState = LoadingState.LOADED;
			return;
		}

		case getType(groupTherapyActions.fetchGroupRegistrationList.failure): {
			state.registrationFetchState = LoadingState.ERROR;
			return;
		}

		case getType(groupTherapyActions.addAGroupTherapy.request): {
			state.savingState = LoadingState.LOADING;
			return;
		}

		case getType(groupTherapyActions.addAGroupTherapy.success): {
			const {group} = action.payload;
			state.groupMap[group.id] = group;
			state.savingState = LoadingState.LOADED;
			return;
		}

		case getType(groupTherapyActions.addAGroupTherapy.failure): {
			state.savingState = LoadingState.ERROR;
			return;
		}

		case getType(groupTherapyActions.editAGroupTherapy.request): {
			state.savingState = LoadingState.LOADING;
			return;
		}

		case getType(groupTherapyActions.editAGroupTherapy.success): {
			const {group} = action.payload;
			state.groupMap[group.id] = group;
			state.savingState = LoadingState.LOADED;
			return;
		}

		case getType(groupTherapyActions.editAGroupTherapy.failure): {
			state.savingState = LoadingState.ERROR;
			return;
		}

		case getType(groupTherapyActions.deleteAGroupTherapy.request): {
			state.deletingState = LoadingState.LOADING;
			return;
		}

		case getType(groupTherapyActions.deleteAGroupTherapy.success): {
			const {id} = action.payload;

			if (state.groupMap[id]) {
				delete state.groupMap[id];
				state.deletingState = LoadingState.LOADED;
			}
			return;
		}

		case getType(groupTherapyActions.deleteAGroupTherapy.failure): {
			state.deletingState = LoadingState.ERROR;
			return;
		}

		case getType(groupTherapyActions.saveGroupRegistrationPlan.request): {
			state.registrationSavingState = LoadingState.LOADING;
			return;
		}

		case getType(groupTherapyActions.saveGroupRegistrationPlan.success): {
			state.registrationSavingState = LoadingState.LOADED;
			return;
		}

		case getType(groupTherapyActions.saveGroupRegistrationPlan.failure): {
			state.registrationSavingState = LoadingState.ERROR;
			return;
		}

		case getType(groupTherapyActions.fetchGroupRegistrationDetails.request): {
			state.registrationFetchState = LoadingState.LOADING;
			return;
		}

		case getType(groupTherapyActions.fetchGroupRegistrationDetails.success): {
			const {registrationDetails, relatedSensorDataMap} = action.payload;
			state.registrationFetchState = LoadingState.LOADED;
			state.registrationDetails = registrationDetails;
			state.relatedSensorDataMap = relatedSensorDataMap;
			return;
		}

		case getType(groupTherapyActions.fetchGroupRegistrationDetails.failure): {
			state.registrationFetchState = LoadingState.ERROR;
			return;
		}

		case getType(groupTherapyActions.deleteGroupRegistration.request): {
			state.registrationDeletingState = LoadingState.LOADING;
			return;
		}
		case getType(groupTherapyActions.deleteGroupRegistration.success): {
			state.registrationDeletingState = LoadingState.LOADED;
			return;
		}

		case getType(groupTherapyActions.deleteGroupRegistration.failure): {
			state.registrationDeletingState = LoadingState.ERROR;
			return;
		}

		case getType(groupTherapyActions.setEditDialogForGroupTherapy): {
			state.groupForDialog = action.payload.group;
			return;
		}

		case getType(groupTherapyActions.clearFetchedRegistrationDetails): {
			state.registrationFetchState = LoadingState.EMPTY;
			state.registrationDetails = null;
			state.relatedSensorDataMap = {};
			return;
		}
	}
}, initialState);
