import {getType} from "typesafe-actions";
import produce from "immer";
import {TaskRegistrationDialogState} from "../taskTypes";
import {LoadingState} from "constants/redux";
import {taskRegistrationActionType, taskRegistrationActions} from "./taskRegistrationActions";

export const initialState: TaskRegistrationDialogState = {
	/**
	 * To determine fetching data request to backend
	 */
	fetchingState: LoadingState.EMPTY,
	/**
	 * task registration response
	 */
	data: null,
	/**
	 * is task detail dialog open
	 */
	isTaskDetailOpen: false,
};

export const taskDialogDetailReducer = produce(
	(state: TaskRegistrationDialogState, action: taskRegistrationActionType): TaskRegistrationDialogState => {
		switch (action.type) {
			case getType(taskRegistrationActions.fetchTaskRegistrationDetailDialog.request): {
				state.data = null;
				state.fetchingState = LoadingState.LOADING;
				return;
			}
			case getType(taskRegistrationActions.fetchTaskRegistrationDetailDialog.success): {
				const {taskRegistrationData} = action.payload;
				state.fetchingState = LoadingState.LOADED;
				state.data = taskRegistrationData;
				return;
			}
			case getType(taskRegistrationActions.fetchTaskRegistrationDetailDialog.failure): {
				state.fetchingState = LoadingState.ERROR;
				return;
			}
			case getType(taskRegistrationActions.resetTaskRegistrationDetailDialog): {
				state.data = null;
				return;
			}
			case getType(taskRegistrationActions.setTaskDetailDialog): {
				const {open} = action.payload;

				state.isTaskDetailOpen = open;
				return;
			}
		}
	},
	initialState,
);
