import {CSSProperties, ComponentType} from "react";
import {GroupTherapyMember, GroupTherapyRegistration} from "@sense-os/goalie-js";
import {LoadingState} from "constants/redux";
import {SensorDatum} from "redux/tracking/TrackingTypes";
import {PlannedEvent as BasePlannedEventEntry} from "@sense-os/sensor-schema/goalie-2-ts/planned_event";

export interface GroupRegistrationItem {
	Icon: ComponentType<{style?: CSSProperties}>;
	title: string;
	dueDate: Date;
	time?: string;
	hasReminder?: boolean;
	registrationId?: number;
	createdBy: number;
}

export interface GroupTherapy {
	id: number;
	name: string;
	createdAt: Date;
	therapists: GroupTherapyMember[];
	clients: GroupTherapyMember[];
	groupRegistrations?: GroupRegistrationItem[];
	totalRegistrations?: number;
}

export interface GroupTherapyReduxState {
	loadingState: LoadingState;
	savingState: LoadingState;
	deletingState: LoadingState;
	registrationFetchState: LoadingState;
	registrationSavingState: LoadingState;
	registrationDeletingState: LoadingState;
	groupMap: Record<number, GroupTherapy>;
	groupForDialog?: Partial<GroupTherapy>;
	registrationDetails?: GroupTherapyRegistration;
	relatedSensorDataMap?: Record<number, SensorDatum<BasePlannedEventEntry>>;
}

/** The first page of table pagination */
export const FIRST_PAGE = 1;

/** The page size of table pagination */
export const PAGE_SIZE = 100;
