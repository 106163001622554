import {call, put, select} from "redux-saga/effects";
import moment from "moment";
import {SensorData as BaseSensorData} from "@sense-os/goalie-js";
import {PlannedEvent as BasePlannedEventEntry} from "@sense-os/sensor-schema/goalie-2-ts/planned_event";

import {SentryTags} from "../../errorHandler/createSentryReport";
import createLogger from "../../logger/createLogger";
import {ActivityTypes, PlannedEventEntry, SensorDatum} from "redux/tracking/TrackingTypes";
import {updatePlannedOmqSmq} from "../../tracker/therapySession/helpers/therapySessionHelpers";
import {apiCallSaga} from "../../helpers/apiCall/apiCall";
import loc from "../../localization/Localization";
import strTranslation from "../../assets/lang/strings";

import {GroupTherapy} from "../groupTherapy";
import groupTherapySDK from "../helpers/groupTherapySdk";
import {getSessionId} from "../../auth/helpers/authStorage";
import {groupTherapyActions} from "../redux/groupTherapyActions";
import {getRegistrationDetails} from "../redux/groupTherapySelectors";
import {getTherapySessionSensors} from "../../clientActivity/helpers/clientActivitySDKHelpers";
import {toastActions} from "../../toaster/redux";
import {
	GroupTherapyRegistration,
	GroupTherapyUpdateSensorData,
	RegistrationType,
} from "@sense-os/goalie-js/dist/groupTherapy/types";

const log = createLogger("updateGroupRegistrationPlanSaga", SentryTags.GroupTherapy);

/**
 * This saga will handle group registration and sensor update
 * Steps:
 * 1. Update omq, smq and session sensor based on client members
 * 2. Update group registration with recent values and given sensorIds
 */
export function* editGroupRegistrationPlan(
	group: GroupTherapy,
	plannedTherapySession: BaseSensorData<BasePlannedEventEntry>,
	isOmqEnabled: boolean,
	isSmqEnabled: boolean,
	type: RegistrationType,
) {
	const token: string = yield call(getSessionId);
	const registrationDetails: GroupTherapyRegistration = yield select(getRegistrationDetails);

	try {
		// 1. Update created omq, smq, and session
		const sensorIds: string[] = registrationDetails.sensorIds;

		const sessionSensorData: SensorDatum<PlannedEventEntry>[] = yield apiCallSaga(
			getTherapySessionSensors,
			token,
			undefined,
			undefined,
			undefined,
			sensorIds,
		);

		const updateTherapySessionResponse = yield call(
			editTherapySession,
			sessionSensorData,
			plannedTherapySession,
			isOmqEnabled,
			isSmqEnabled,
		);
		const updatedSensorIds: string[] = updateTherapySessionResponse.updatedSensorIds;
		const updatedTherapySession: BaseSensorData<BasePlannedEventEntry> =
			updateTherapySessionResponse.plannedTherapySession;

		// 2. Update group registration
		yield apiCallSaga(groupTherapySDK.updateRegistration, token, group.id, registrationDetails.id, {
			sensorIds: updatedSensorIds,
			title: updatedTherapySession.value.title,
			dueDate: moment(updatedTherapySession.startTime).toDate(),
			details: {...updatedTherapySession, startTime: moment(updatedTherapySession.startTime).toDate()},
			type,
		});

		yield put(groupTherapyActions.saveGroupRegistrationPlan.success());
		yield put(groupTherapyActions.fetchGroupRegistrationList.request({id: group.id}));

		// Clear registration details and sessionDataMap
		yield put(groupTherapyActions.clearFetchedRegistrationDetails());

		// Show success toast message
		const dateText: string = moment(updatedTherapySession.startTime).format("DD MMMM");
		const timeText: string = `${moment(updatedTherapySession.startTime).format("HH:mm")} - ${moment(
			updatedTherapySession.endTime,
		).format("HH:mm")}`;
		const successToastText: string = loc.formatMessage(
			strTranslation.GRAPHS.new_event.plan.THERAPY_SESSION.success.toast,
			{dateText, timeText},
		);
		yield put(toastActions.addToast({message: successToastText, type: "info"}));
	} catch (err) {
		log.captureException(err, {message: `Failed to update group registration plan.`});
		yield put(groupTherapyActions.saveGroupRegistrationPlan.failure(err));
	}
}

function* editTherapySession(
	listOfSensorData: SensorDatum<PlannedEventEntry>[],
	plannedTherapySession: BaseSensorData<BasePlannedEventEntry>,
	isOmqEnabled: boolean,
	isSmqEnabled: boolean,
) {
	const token: string = yield call(getSessionId);
	const therapySessionBulkPayload: GroupTherapyUpdateSensorData<BasePlannedEventEntry>[] = [];

	try {
		for (const sensorData of listOfSensorData) {
			const copiedPlannedTherapySession: BaseSensorData<BasePlannedEventEntry> = JSON.parse(
				JSON.stringify(plannedTherapySession),
			);
			const plannedOmq = sensorData.value.plannedOmq;
			const plannedSmq = sensorData.value.plannedSmq;

			// Here we want to save or delete Planned OMQ
			const plannedOmqUri = yield call(
				updatePlannedOmqSmq,
				sensorData.userId,
				copiedPlannedTherapySession,
				ActivityTypes.FILL_OMQ,
				isOmqEnabled,
				plannedOmq && plannedOmq.sensorData,
			);

			// Try to attach Planned OMQ Uri from backend into `plannedTherapySession`
			if (plannedOmqUri) {
				copiedPlannedTherapySession.value.plannedOmq = {
					"%uri": plannedOmqUri,
				};

				/**
				 * assign the omq URI to the group therapy registration
				 * visually it just used for a switch button (read: boolean / on-off)
				 * so the URI id doesn't matter
				 */
				plannedTherapySession = {
					...plannedTherapySession,
					value: {
						...plannedTherapySession.value,
						plannedOmq: {
							"%uri": plannedOmqUri,
						},
					},
				};
			} else {
				// If the uri does not exist, delete the property from the object.
				delete copiedPlannedTherapySession.value.plannedOmq;
			}

			// Here we want to save or delete Planned SMQ
			const plannedSmqUri = yield call(
				updatePlannedOmqSmq,
				sensorData.userId,
				copiedPlannedTherapySession,
				ActivityTypes.FILL_SMQ,
				isSmqEnabled,
				plannedSmq && plannedSmq.sensorData,
			);

			// Try to attach Planned SMQ Uri from backend into `plannedTherapySession`
			if (plannedSmqUri) {
				copiedPlannedTherapySession.value.plannedSmq = {
					"%uri": plannedSmqUri,
				};

				/**
				 * assign the smq URI to the group therapy registration
				 * visually it just used for a switch button (read: boolean / on-off)
				 * so the URI id doesn't matter
				 */
				plannedTherapySession = {
					...plannedTherapySession,
					value: {
						...plannedTherapySession.value,
						plannedSmq: {
							"%uri": plannedSmqUri,
						},
					},
				};
			} else {
				// If the uri does not exist, delete the property from the object.
				delete copiedPlannedTherapySession.value.plannedSmq;
			}

			const formattedSessionPayload: GroupTherapyUpdateSensorData<BasePlannedEventEntry> = {
				_id: sensorData.id,
				end_time: copiedPlannedTherapySession.endTime as unknown as string,
				start_time: copiedPlannedTherapySession.startTime as unknown as string,
				value: copiedPlannedTherapySession.value,
			};
			therapySessionBulkPayload.push(formattedSessionPayload);
		}

		yield apiCallSaga(groupTherapySDK.updateBulkSensors, token, therapySessionBulkPayload);

		// since from the `updateBulkSensor` doesn't return any response, map the ids from `listOfSensorData`
		const updatedSensorIds: string[] = listOfSensorData.map((sensorData) => sensorData.id);

		return {
			updatedSensorIds,
			plannedTherapySession,
		};
	} catch (err) {
		log.captureException(err, {message: `Failed to update planned omq or smq.`});
	}
}
