import {ActionType, createAction, createAsyncAction} from "typesafe-actions";
import {GroupTherapyRegistration, RegistrationType} from "@sense-os/goalie-js";
import {PlannedEvent as BasePlannedEventEntry} from "@sense-os/sensor-schema/goalie-2-ts/planned_event";
import {wrapInDispatch} from "../../helpers/wrapInDispatch";
import {GroupRegistrationItem, GroupTherapy} from "../groupTherapy";
import {TherapySessionFormValues} from "../../tracker/therapySession/types";
import {SensorDatum} from "redux/tracking/TrackingTypes";

export const groupTherapyActions = {
	fetchGroupTherapyList: createAsyncAction(
		"groupTherapyActions/fetchGroupTherapyList",
		"groupTherapyActions/fetchGroupTherapyListSucceed",
		"groupTherapyActions/fetchGroupTherapyListFailed",
	)<void, {groups: GroupTherapy[]}, {error: Error}>(),

	addAGroupTherapy: createAsyncAction(
		"groupTherapyActions/addAGroupTherapy",
		"groupTherapyActions/addAGroupTherapySucceed",
		"groupTherapyActions/addAGroupTherapyFailed",
	)<{group: Omit<GroupTherapy, "id" | "createdAt">}, {group: GroupTherapy}, {error: Error}>(),

	editAGroupTherapy: createAsyncAction(
		"groupTherapyActions/editAGroupTherapy",
		"groupTherapyActions/editAGroupTherapySucceed",
		"groupTherapyActions/editAGroupTherapyFailed",
	)<
		{id: number; groupUpdate: Partial<GroupTherapy>},
		{id: number; group: GroupTherapy},
		{id: number; error: Error}
	>(),

	deleteAGroupTherapy: createAsyncAction(
		"groupTherapyActions/deleteAGroupTherapy",
		"groupTherapyActions/deleteAGroupTherapySucceed",
		"groupTherapyActions/deleteAGroupTherapyFailed",
	)<{id: number}, {id: number}, {error: Error}>(),

	fetchGroupRegistrationList: createAsyncAction(
		"groupTherapyActions/fetchGroupRegistrationList",
		"groupTherapyActions/fetchGroupRegistrationListSucceed",
		"groupTherapyActions/fetchGroupRegistrationListFailed",
	)<
		{id: number; page?: number; pageSize?: number},
		{id: number; registrations: GroupRegistrationItem[]; totalRegistrations: number},
		{error: Error}
	>(),

	saveGroupRegistrationPlan: createAsyncAction(
		"groupTherapyActions/saveGroupRegistrationPlan",
		"groupTherapyActions/saveGroupRegistrationPlanSucceed",
		"groupTherapyActions/saveGroupRegistrationPlanFailed",
	)<
		{group: GroupTherapy; sessionFormValues: TherapySessionFormValues; isEdit: boolean; type: RegistrationType},
		void,
		{error: Error}
	>(),

	setEditDialogForGroupTherapy: createAction(
		"groupTherapyActions/setEditDialogForGroupTherapy",
		(group: Partial<GroupTherapy>) => ({group}),
	)(),

	fetchGroupRegistrationDetails: createAsyncAction(
		"groupTherapyActions/fetchGroupRegistrationDetails",
		"groupTherapyActions/fetchGroupRegistrationDetailsSucceed",
		"groupTherapyActions/fetchGroupRegistrationDetailsFailed",
	)<
		{groupId: number; registrationId: number},
		{
			registrationDetails: GroupTherapyRegistration;
			relatedSensorDataMap: Record<number, SensorDatum<BasePlannedEventEntry>>;
		},
		{error: Error}
	>(),

	clearFetchedRegistrationDetails: createAction("groupTherapyActions/clearFetchedRegistrationDetails")(),

	deleteGroupRegistration: createAsyncAction(
		"groupTherapyActions/deleteGroupRegistration",
		"groupTherapyActions/deleteGroupRegistrationSucceed",
		"groupTherapyActions/deleteGroupRegistrationFailed",
	)<{groupId: number; registrationDetails: GroupTherapyRegistration}, void, {error: Error}>(),
};

export type GroupTherapyActions = ActionType<typeof groupTherapyActions>;
export const groupTherapyActionsWithDispatch = wrapInDispatch(groupTherapyActions);
