import {CSSProperties, ComponentType} from "react";
import {
	GroupTherapyMember,
	GroupTherapyRegistration,
	RegistrationType,
	SensorData as BaseSensorData,
	GroupTherapySensor,
} from "@sense-os/goalie-js";
import {PlannedEvent as BasePlannedEventEntry} from "@sense-os/sensor-schema/goalie-2-ts/planned_event";

import ScienceIcon from "../../assets/icons/science.svg";
import TherapySession from "../../assets/icons/therapy-session.svg";
import PsychoEducation from "../../assets/icons/psychoeducation.svg";
import MeetingNotes from "../../assets/icons/meeting-notes.svg";
import {Contact} from "../../contacts/contactTypes";
import {withLocalizedTooltip} from "../../components/LocalizedTooltip";
import strTranslation from "../../assets/lang/strings";

import {GroupRegistrationItem, GroupTherapy} from "../groupTherapy";
import {PlannedEventStatus, SensorDatum} from "redux/tracking/TrackingTypes";

/**
 * Get group therapy member ids from given client and therapist contacts
 */
export const getGroupMemberIds = (clients: GroupTherapyMember[], therapists: GroupTherapyMember[]): number[] => {
	const memberIds: number[] = [];
	clients?.forEach((client) => memberIds.push(client.id));
	therapists?.forEach((therapist) => memberIds.push(therapist.id));
	return memberIds;
};

/**
 * Get latest updated member ids of group therapy
 */
export const getUpdatedMemberIds = (existingGroup: GroupTherapy, updatedGroup: GroupTherapy): number[] => {
	let isTherapistsUpdated: boolean = false;
	let isClientsUpdated: boolean = false;

	// Get existing therapist and client ids
	const existingTherapistIds: number[] = existingGroup.therapists?.map((therapist) => therapist.id);
	const existingClientIds: number[] = existingGroup.clients?.map((client) => client.id);

	// Get updated therapist and client ids
	const newTherrapistIds: number[] = updatedGroup.therapists?.map((therapist) => therapist.id);
	const newClientIds: number[] = updatedGroup.clients?.map((client) => client.id);

	if (JSON.stringify(existingTherapistIds) !== JSON.stringify(newTherrapistIds)) {
		// Check if therapist members is updated
		isTherapistsUpdated = true;
	}

	if (JSON.stringify(existingClientIds) !== JSON.stringify(newClientIds)) {
		// Check if client members is updated
		isClientsUpdated = true;
	}

	return isTherapistsUpdated || isClientsUpdated ? newTherrapistIds.concat(newClientIds) : [];
};

/**
 * Get icon and tooltip for registration table item
 */
export const getRegistrationIcon = (type: RegistrationType): ComponentType<{style?: CSSProperties}> => {
	switch (type) {
		case RegistrationType.BEHAVIOR_EXPERIMENT:
			return withLocalizedTooltip(ScienceIcon, strTranslation.ZZ_CORE_TRACKER.tracker.behavior_experiment.name);
		case RegistrationType.HOMEWORK:
			return withLocalizedTooltip(PsychoEducation, strTranslation.ZZ_CORE_TRACKER.tracker.homework.name);
		case RegistrationType.SESSION:
			return withLocalizedTooltip(TherapySession, strTranslation.ZZ_THERAPY_SESSION.name);
		case RegistrationType.SESSION_NOTE:
			return withLocalizedTooltip(MeetingNotes, strTranslation.ZZ_CORE_TRACKER.tracker.meeting_note.name);
	}
};

/**
 * Convert group therapy registration list into registration table item
 */
export const convertRegistrationListIntoRegistrationItems = (
	registrations: GroupTherapyRegistration[],
): GroupRegistrationItem[] => {
	const items: GroupRegistrationItem[] =
		registrations?.map((registration) => {
			return {
				title: registration.title,
				dueDate: registration.dueDate,
				hasReminder: registration?.details?.value?.shouldSendNotification,
				registrationId: registration.id,
				Icon: getRegistrationIcon(registration.type),
				createdBy: registration.createdBy,
			};
		}) || [];
	return items;
};

/**
 * Convert portal Contact type into GroupTherapyMember
 */
export const convertContactIntoGroupTherapyMember = (contact: Contact): GroupTherapyMember => {
	return {
		id: contact.id,
		firstName: contact.firstName,
		lastName: contact.lastName,
		image: contact.image,
		email: contact.email,
	};
};

/**
 * Merge Base Planned Event Entry and Group Therapy Member
 * to `GroupTherapySensor<BasePlannedEventEntry>[]`
 */
export const mergeBasePlannedEventEntryAndClients = (
	sensor: BaseSensorData<BasePlannedEventEntry>,
	clients: GroupTherapyMember[],
): GroupTherapySensor<BasePlannedEventEntry>[] => {
	return clients.map((client) => {
		return {
			...sensor,
			userId: client.id,
		};
	});
};

/**
 * The registration could be deleted as long as
 * at least no 1 planned event status is `COMPLETED` or `CANCELED`
 */
export const isGroupTherapySessionDeletable = (relatedSensors: SensorDatum<BasePlannedEventEntry>[]): boolean => {
	const statuses = relatedSensors.map((relatedSensor) => {
		return relatedSensor.value.status;
	});
	const isCompleted: boolean = statuses.includes(PlannedEventStatus.COMPLETE);
	const isCanceled: boolean = statuses.includes(PlannedEventStatus.CANCELED);

	return !(isCompleted || isCanceled);
};

/**
 * The registration could be edited as long as
 * at least no 1 planned event status is `COMPLETED`
 */
export const isGroupTherapySessionEditable = (relatedSensors: SensorDatum<BasePlannedEventEntry>[]): boolean => {
	const statuses = relatedSensors.map((relatedSensor) => {
		return relatedSensor.value.status;
	});
	const isCompleted: boolean = statuses.includes(PlannedEventStatus.COMPLETE);

	return !isCompleted;
};
