import {LoadingState} from "constants/redux";
import {ReminderTimeUnit} from "../helpers/time";
import {TaskItem, TaskItemResponse, TaskRecurrenceType, TrackerItem} from "@sense-os/goalie-js";
import {NumberMap} from "services/utils/Maps";

export interface TaskFormValues {
	title?: string;
	expiresAt?: Date;
	// reminder time unit
	reminderTimeUnit?: ReminderTimeUnit;
	// reminder "before" duration
	reminderDuration?: number;
	// repeated option
	reminderRepeatOption?: TaskRecurrenceType;
	// switch button props
	expiresAtSwitchButton?: boolean;
	reminderSwitchButton?: boolean;
	reminderRepeatOptionSwitchButton?: boolean;
}

export interface TaskRegistrationDialogState {
	// fetching state
	fetchingState: LoadingState;
	// detail Task data
	data: TaskItem<any>;
	// is task detail dialog open
	isTaskDetailOpen: boolean;
}

export enum TaskFormActionType {
	CREATING = "creating",
	EDITING = "editing",
	PLAN_AGAIN = "plan_again",
}

export const taskDateOptions = {
	fixed: {
		value: "fixed",
		label: "Do on a fixed date and time.",
		tooltip: "Ask your client to do something at a specific date and time. Example: “Get up at 7:30”.",
	},
	timeRange: {
		value: "timeRange",
		label: "Do within a time range.",
		tooltip: "Ask your client to do something in a time range. Example: “Selfcare time between 8:00 and 9:00”.",
	},
};

export interface SensorTaskState {
	// task saving state
	savingState: LoadingState;
	// task fetching state
	fetchingState: LoadingState;
	// selected tracker
	activeTracker: TrackerItem;
	// task modal form status
	isModalFormOpen: boolean;
	// Task form values map by user id and sensorName
	savedFormValues: NumberMap<Record<string, TaskFormValues>>;
	// Task form action
	formAction: TaskFormActionType;
	// task deleting state
	deletingState: LoadingState;
	// Deleting Modal open status
	isDeletingModalOpen: boolean;
}

export interface RelatedTaskState {
	// task fetching state
	fetchingState: LoadingState;
	// linked task
	data: TaskItemResponse<any>;
}

export interface TaskRegistrationState {
	/**
	 * State for sensor task
	 */
	sensor: SensorTaskState;
	/**
	 * State for task detail Dialog
	 */
	taskDetailDialog: TaskRegistrationDialogState;
	/**
	 * State for checking correlation between tracker & task
	 */
	relatedTask: RelatedTaskState;
}
