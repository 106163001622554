import {
	TrackerCategory as SDKTrackerCategory,
	Tracker as SDKTracker,
	Measure as SDKMeasure,
	TrackerItem,
	TRACKER_MODEL_NAME,
} from "@sense-os/goalie-js";
import {
	TrackerCategory,
	Tracker,
	TrackerMeasure,
	CustomTrackerSensor,
	TrackerDatum,
	MeasureValuesMap,
	TrackerDataMap,
} from "../customTrackerTypes";
import localization from "../../../localization/Localization";
import {SensorDatum, Sensors} from "redux/tracking/TrackingTypes";
import featureFlags from "../../../featureFlags/FeatureFlags";
import {CoreTrackerModelNames, defaultTrackerCategory, isCoreTrackers} from "../../utils/coreTracker";
import {getTranslatedCustomTracker} from "./trackerLocalizations";
import {TrackerSensorName} from "../customTrackerSensorTypes";
import {TrackerModelName} from "@sense-os/goalie-js/dist/trackers/types";

const localizeCoreTrackerTextFromBE = (txt: string) => {
	return localization.formatMessage(`ZZ_CORE_TRACKER.${txt}`) || "";
};

export const localiseTextFromBE = (txt: string) => {
	return localization.formatMessage(`ZZ_CUSTOM_TRACKER.${txt}`) || "";
};

export function transformSDKTrackerCategory(category: SDKTrackerCategory): TrackerCategory {
	// since visually the `default` trackers are not shown in the set up new tracker modal
	// doesn't need to be translated
	const transformedCategory: string = (() => {
		if (category.category !== defaultTrackerCategory) {
			return localiseTextFromBE(category.category);
		}

		return "";
	})();

	return {
		category: transformedCategory,
		id: category.category,
		trackers: category.trackers.map(transformSDKTracker),
	};
}

export function transformSDKTracker(tracker: SDKTracker): Tracker {
	const isDefaultTracker: boolean = tracker.category === defaultTrackerCategory;

	return {
		category: isDefaultTracker ? "" : localiseTextFromBE(tracker.category),
		description: isDefaultTracker ? "" : localiseTextFromBE(tracker.description),
		id: tracker.id,
		measures: tracker.measures.map(transformSDKMeasure),
		tags: tracker.tags.map((tag) => localiseTextFromBE(tag)),
		trackerName: getTranslatedCustomTracker(tracker.sensorName as TrackerSensorName),
		sensorName: !!tracker.sensorName ? tracker.sensorName : tracker.modelName,
		scheduleType: tracker.sensorName,
		iconName: tracker.sensorName,
	};
}

export function transformSDKMeasure(measure: SDKMeasure): TrackerMeasure {
	return {
		description: localiseTextFromBE(measure.description),
		iconName: measure.iconName,
		name: localiseTextFromBE(measure.name),
	};
}

/**
 * Returns `value` as string if `value` is an integer.
 * otherwise rounds to 1 decimal.
 */
export function numberToString(value: number, decimal: number = 1): string {
	if (Number.isInteger(value)) {
		return value.toString();
	}
	// Cast to number to prevent "1.0" to be returned.
	// e.g (1.0005).toFixed(1) will return "1.0",
	// by using `Number(..)`, "1.0" will be converted to "1"
	return Number(value.toFixed(decimal)).toString();
}

export function convertCustomTrackerSensorToTrackerData(customTracker: SensorDatum<CustomTrackerSensor>): TrackerDatum {
	if (!customTracker.value.measures) {
		return null;
	}
	return {
		sensorId: customTracker.id,
		date: customTracker.startTime,
		trackerName: customTracker.sensorName,
		values: getMeasureValuesFromCustomTrackerSensor(customTracker),
	};
}

function getMeasureValuesFromCustomTrackerSensor(customTracker: SensorDatum<CustomTrackerSensor>): MeasureValuesMap {
	if (!customTracker.value.measures) {
		return {};
	}

	return Object.entries(customTracker.value.measures).reduce((trackerValues: MeasureValuesMap, [key, value]) => {
		trackerValues[key] = value.sensorData.value;
		return trackerValues;
	}, {});
}

/**
 * Returns duration value in minute (divided by 60)
 */
export const getDurationInMinute = (duration: number) => {
	if (duration === null || duration === undefined) {
		return null;
	}
	return duration / 60;
};

/**
 * Get maximum and minimum values of given `yValues` + additional buffer.
 * These values will be used as yDomain on custom tracker chart
 *
 * @param yValues
 * @param buffer
 */
export function getYDomainWithBuffer(yValues: number[], buffer: number): [number, number] {
	const maxYValue = Math.max(...yValues) + buffer;
	const minYValue = Math.min(...yValues) - buffer;
	const max = Math.floor(maxYValue / buffer) * buffer;
	const min = Math.ceil(minYValue / buffer) * buffer;
	return [max, min < 0 ? 0 : min];
}

/**
 * Merge tracker order (list of trackerId in order) with UserTrackers
 *
 * @param trackerOrder
 * @param userTrackerIds
 */
export function mergeTrackerOrderWithUserTrackers(trackerOrder: number[], userTrackerIds: number[]): number[] {
	return [...trackerOrder, ...userTrackerIds].reduce((distinct, id) => {
		if (distinct.indexOf(id) === -1) {
			distinct.push(id);
		}
		return distinct;
	}, []);
}

/** List of tracker version based on the development iteration
 * https://docs.google.com/spreadsheets/d/1hdDZOeWjLIz918GZDqIaHEAPwxJh7OWT7xOGTiisPJg/
 */
export enum TrackerVersion {
	VERSION_1 = 1,
	VERSION_2 = 2,
	VERSION_3 = 3,
	VERSION_4 = 4,
	VERSION_5 = 5,
}

/**
 * Returns `tracker_version` to be fetched from the backend.
 *
 * @see https://github.com/senseobservationsystems/goalie-js/issues/724
 * if `trackerV4` is enabled, it should return `TrackerVersion.VERSION_4`
 * if `coreTracker` is enabled, it should return `TrackerVersion.VERSION_3`
 * if `customTrackerPart2` is enabled, it should return `TrackerVersion.VERSION_2`
 * if both of them is disabled return `TrackerVersion.VERSION_1`
 * if `coreTracker` and `customTrackerPart2` are enabled, return `TrackerVersion.VERSION_3`
 */
export function getCustomTrackerVersion() {
	if (featureFlags.customExercise) return TrackerVersion.VERSION_5;
	if (featureFlags.trackersV4) return TrackerVersion.VERSION_4;
	if (featureFlags.trackersV3) return TrackerVersion.VERSION_3;
	if (featureFlags.customTrackerPart2) return TrackerVersion.VERSION_2;

	return TrackerVersion.VERSION_1;
}

/**
 * Convert tracker item to use translation
 * @param tracker
 * @returns TrackerItem
 */
export function transformTrackerItem(tracker: TrackerItem): TrackerItem {
	const sensorName = !!CoreTrackerModelNames[tracker.modelName] ? tracker.modelName : tracker.sensorName;
	return {
		id: tracker.id,
		user: tracker.user,
		trackerName: getTranslatedTrackerNameFromBE(tracker, sensorName),
		sensorName: sensorName,
		isEnabled: tracker.isEnabled,
		createdAt: tracker.createdAt,
		iconName: tracker.sensorName,
		registrationLastUpdated: tracker.registrationLastUpdated,
		registrationTotal: tracker.registrationTotal,
		modelName: tracker.modelName,
	};
}

/**
 * Get Tracker Name localize text based on `TrackerModelName`
 * @param tracker
 * @returns string
 */
function getTranslatedTrackerNameFromBE(tracker: TrackerItem, sensorName: string): string {
	if (isCoreTrackers(sensorName)) {
		return localizeCoreTrackerTextFromBE(tracker.trackerName);
	}

	return localiseTextFromBE(tracker.trackerName);
}

/**
 * Transform sensor datums to tracker data map
 * @param sensorDatums
 * @param sensorNames
 * @returns TrackerDataMap
 */
export function transformSensorDatumsToTrackerDataMap(
	sensorDatums: SensorDatum<any>[],
	sensorNames: Sensors[],
): TrackerDataMap {
	// init tracker data map with empty array for each sensorName key
	const trackerDataMap = sensorNames.reduce((map: TrackerDataMap, sensorName) => {
		map[sensorName] = [];
		return map;
	}, {});
	// push sensor datum to specific tracker data map key
	sensorDatums.forEach((sensorDatum) => {
		trackerDataMap[sensorDatum.sensorName].push(convertCustomTrackerSensorToTrackerData(sensorDatum));
	});
	// asc sort by date
	Object.keys(trackerDataMap).forEach((mapKey) => {
		trackerDataMap[mapKey].sort((a, b) => {
			return a.date.valueOf() - b.date.valueOf();
		});
	});

	return trackerDataMap;
}

const coreTrackerModelMap = {
	[TRACKER_MODEL_NAME.BEHAVIOR_EXPERIMENT]: TRACKER_MODEL_NAME.BEHAVIOR_EXPERIMENT,
	[TRACKER_MODEL_NAME.HOMEWORK]: TRACKER_MODEL_NAME.HOMEWORK,
};

/**
 * Transform Tracker type to Tracker item
 */
export function transformTrackerToTrackerItem(tracker: Tracker, isEnabled: boolean): TrackerItem {
	const modelName: TrackerModelName = coreTrackerModelMap?.[tracker.sensorName] ?? TRACKER_MODEL_NAME.SENSOR_DATA;
	return {
		createdAt: null,
		iconName: tracker.iconName,
		id: tracker.id,
		isEnabled,
		sensorName: tracker.sensorName,
		trackerName: tracker.trackerName,
		modelName,
	};
}
