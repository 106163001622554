import {fork} from "redux-saga/effects";
import fetchGroupTherapySaga from "./fetchGroupTherapySaga";
import fetchGroupRegistrationListSaga from "./fetchGroupRegistrationListSaga";
import fetchGroupRegistrationDetailsSaga from "./fetchGroupRegistrationDetailsSaga";
import saveGroupTherapySaga from "./saveGroupTherapySaga";
import saveGroupRegistrationPlanSaga from "./saveGroupRegistrationPlanSaga";
import editGroupTherapySaga from "./editGroupTherapySaga";
import deleteGroupTherapySaga from "./deleteGroupTherapySaga";
import deleteGroupRegistrationSaga from "./deleteGroupRegistrationSaga";

export default function* () {
	yield fork(fetchGroupTherapySaga);
	yield fork(fetchGroupRegistrationListSaga);
	yield fork(fetchGroupRegistrationDetailsSaga);
	yield fork(saveGroupTherapySaga);
	yield fork(saveGroupRegistrationPlanSaga);
	yield fork(editGroupTherapySaga);
	yield fork(deleteGroupTherapySaga);
	yield fork(deleteGroupRegistrationSaga);
}
