import {getType} from "typesafe-actions";
import {LoadingState} from "constants/redux";
import {CustomExerciseFormActionType, CustomExerciseState} from "../customExerciseTypes";
import produce from "immer";
import {customExerciseActions, CustomExerciseActionType} from "./customExerciseActions";

const initialState: CustomExerciseState = {
	userId: null,
	exerciseId: null,
	taskId: null,
	isFormOpened: false,
	savingState: LoadingState.EMPTY,
	savedFormValues: {},
	formAction: null,
};

export const customExerciseReducer = produce(
	(state: CustomExerciseState = initialState, action: CustomExerciseActionType) => {
		switch (action.type) {
			// opening and closing modal form
			case getType(customExerciseActions.openForm):
				const formAction = action.payload.formAction;
				state.userId = action.payload.userId;
				state.exerciseId = action.payload.exerciseId || null;
				state.taskId = action.payload.taskId || null;
				state.formAction = formAction;
				state.isFormOpened = true;
				if (formAction === CustomExerciseFormActionType.EDIT) {
					state.savedFormValues = {
						...state.savedFormValues,
						[action.payload.userId]: action.payload.formValue,
					};
				}
				return;
			case getType(customExerciseActions.closeForm):
				state.userId = null;
				state.isFormOpened = false;
				state.savingState = LoadingState.EMPTY;
				state.formAction = null;
				return;

			// save form value per user
			case getType(customExerciseActions.saveFormValue): {
				state.savedFormValues = {
					...state.savedFormValues,
					[action.payload.userId]: action.payload.formValues,
				};
				return;
			}

			// creating custom exercise
			case getType(customExerciseActions.createCustomExercise.request): {
				state.savingState = LoadingState.LOADING;
				return;
			}
			case getType(customExerciseActions.createCustomExercise.success): {
				const {userId} = action.payload;
				state.userId = null;
				state.savingState = LoadingState.LOADED;
				state.savedFormValues = {
					...state.savedFormValues,
					[userId]: null,
				};

				return;
			}
			case getType(customExerciseActions.createCustomExercise.failure): {
				state.savingState = LoadingState.ERROR;
				return;
			}

			// editing custom exercise
			case getType(customExerciseActions.editCustomExercise.request): {
				state.savingState = LoadingState.LOADING;
				return;
			}
			case getType(customExerciseActions.editCustomExercise.success): {
				const {userId} = action.payload;
				state.userId = null;
				state.savingState = LoadingState.LOADED;
				state.savedFormValues = {
					...state.savedFormValues,
					[userId]: null,
				};

				return;
			}
			case getType(customExerciseActions.editCustomExercise.failure): {
				state.savingState = LoadingState.ERROR;
				return;
			}
		}
	},
	initialState,
);
