import {LoadingState} from "constants/redux";
import {TIME_UNITS} from "constants/time";
import moment from "moment";
import {NumberMap} from "services/utils/Maps";
import strTranslation from "../../assets/lang/strings";
import {ReminderTimeUnit} from "../../helpers/time";
import localization from "../../localization/Localization";
import {taskRecurrenceOptions} from "../../taskRegistration/helpers/taskRecurringHelper";
import {TaskFormValues} from "../../taskRegistration/taskTypes";

export interface CustomExerciseState {
	// client Id
	userId: number;
	// exercise id
	exerciseId?: number;
	// task id
	taskId?: number;
	// exercise form isOpen state
	isFormOpened: boolean;
	// form action
	formAction: CustomExerciseFormActionType;
	// exercise saving state
	savingState: LoadingState;
	// exercise form values map
	savedFormValues: NumberMap<ICustomExerciseForm>;
}

export interface ICustomExerciseForm extends TaskFormValues {
	// content instruction
	content: string;
}

export const customExerciseDefaultFormValues: ICustomExerciseForm = {
	title: "",
	content: localization.formatMessage(strTranslation.CUSTOM_EXERCISE.form_dialog.default_instruction),
	expiresAtSwitchButton: true,
	// set default value to 6 days after created
	expiresAt: moment().add(6, TIME_UNITS.DAY).startOf(TIME_UNITS.HOURS).toDate(),
	reminderSwitchButton: true,
	reminderDuration: 1,
	reminderTimeUnit: ReminderTimeUnit.HOURS,
	reminderRepeatOptionSwitchButton: false,
	reminderRepeatOption: taskRecurrenceOptions[0],
};

export enum CustomExerciseFormActionType {
	CREATE = "create",
	EDIT = "edit",
}
