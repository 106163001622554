import strTranslation from "../../assets/lang/strings";
import localization from "../../localization/Localization";
import {TaskRecurrenceType} from "@sense-os/goalie-js";

// mapped task recurrence translation key
export const taskRecurrenceTranslationMapKey: Record<TaskRecurrenceType, string> = {
	[TaskRecurrenceType.DAILY]: strTranslation.TASK.dialog.recurrence.option.daily,
	[TaskRecurrenceType.WEEKDAYS]: strTranslation.TASK.dialog.recurrence.option.weekday,
	[TaskRecurrenceType.WEEKLY]: strTranslation.TASK.dialog.recurrence.option.weekly,
	[TaskRecurrenceType.MONTHLY]: strTranslation.TASK.dialog.recurrence.option.monthly,
};

// task v1 doesn't support custom repetition
export const taskRecurrenceOptions = Object.values(TaskRecurrenceType).map((item) => {
	return item;
});

type TranslatedTaskRecurrenceOptionParamsType = {
	recurrenceOption: TaskRecurrenceType;
};

/*
 * This function will return translated recurrence option for Task Form
 */
export const getTranslatedTaskRecurrenceOption = (params: TranslatedTaskRecurrenceOptionParamsType): string => {
	const {recurrenceOption} = params;

	const translationKey = taskRecurrenceTranslationMapKey[recurrenceOption];

	return localization.formatMessage(translationKey);
};

// mapped task recurrence translation key for dialog
export const taskDialogRecurrenceTranslationMapKey: Record<TaskRecurrenceType, string> = {
	[TaskRecurrenceType.DAILY]: strTranslation.TASK.dialog.recurrence.field.daily,
	[TaskRecurrenceType.WEEKDAYS]: strTranslation.TASK.dialog.recurrence.field.weekday,
	[TaskRecurrenceType.WEEKLY]: strTranslation.TASK.dialog.recurrence.field.weekly,
	[TaskRecurrenceType.MONTHLY]: strTranslation.TASK.dialog.recurrence.field.monthly,
};
